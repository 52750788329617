<template>
  <div id="app">
    <SplashComponent />
  </div>
</template>

<script>
// import HeaderComponent from './components/HeaderComponent.vue';
// import HeroSection from './components/HeroSection.vue';
import SplashComponent from './components/SplashComponent.vue';

export default {
  name: 'App',
  mounted() {
    document.title = "Crazy Dreamin' Nomads";
  },
  components: {
    SplashComponent,
  },
};
</script>

<style>
html, body {
    height: 100%;
    margin: 0;
    display: flex;
    flex-direction: column;
}
</style>