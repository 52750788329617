

<template>
<div class="splash-container">
    <div class="splash-content">
        <h1>Crazy Dreamin' Nomads</h1>
        <p>Starting our adventure in Sept. 2024</p>
        <SocialLinks />
    </div>
</div>
<div>
        <FooterComponent />
    </div>
</template>

<script>
    import SocialLinks from './SocialLinks.vue';
    import FooterComponent from './FooterComponent.vue';

    export default {
        name: 'App',
        components: {
            SocialLinks,
            FooterComponent,
        },
    };
</script>