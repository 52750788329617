<template>
    <footer class="footer">
      <div class="container">
        <p>&copy; 2024 Crazy Dreamin Nomads. All rights reserved.</p>
      </div>
    </footer>
  </template>
  
  <script>
  export default {
    name: 'FooterComponent',
  };
  </script>
  
  <style scoped>
.footer{
    background-color: #aaaaaa;
    text-align: center;
    padding: 1rem;
    width: 100%;
    margin-top: auto; /* Pushes footer to the bottom */
    color: #ffffff;
    font-size: 14px;
    text-decoration: none;
    transition: color 0.3s;
  }
  </style>
  