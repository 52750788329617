<template>
  <div class="social-links">
    <a
      v-for="(social, index) in socialLinks"
      :key="index"
      :href="social.url"
      :aria-label="social.label"
      target="_blank"
      rel="noopener noreferrer"
      class="social-link"
    >
      <i :class="social.icon"></i>
    </a>
  </div>
</template>
  
  <script>
  export default {
    name: 'SocialLinks',
    data() {
      return {
        socialLinks: [
          {
            url: 'https://www.instagram.com/crazy.dreamin.nomads',
            label: 'Instagram',
            icon: 'fab fa-instagram',
          },
          {
            url: 'https://www.tiktok.com/@crazy.dreamin.nomads',
            label: 'TikTok',
            icon: 'fab fa-tiktok',
          },
          {
            url: 'https://www.youtube.com/@crazydreaminnomads',
            label: 'YouTube',
            icon: 'fab fa-youtube',
          },
        ],
      };
    },
  };
  </script>
  
  <style scoped>
  .social-links {
    display: flex;
    gap: 10px;
  }
  
  .social-link {
    color: #ffffff;
    font-size: 24px;
    text-decoration: none;
    transition: color 0.3s;
  }
  
  .social-link:hover {
    color: #00c3ff; /* Change this to the color you want on hover */
  }
  </style>
  